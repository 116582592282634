<template>
  <div class="bg-[#0f0f0f] font-Montserrat">
    <div class="video-container">
      <video id="video" autoplay loop playsinline>
        <source src="../assets/Showreel-video.mp4" type="video/mp4" />
      </video>
      <navbar id="navbar" @scrollto="goto" :class="{'change_color lg:opacity-30': scrollPosition > 600}" class="p-[30px] change_navbar w-full sm:bg-none"></navbar>
    </div>
    <div ref="portfolio">
    <div class="p-7 md:p-14 mt-12">
      <p class="flex w-full justify-start text-xl md:text-2xl uppercase font-MontserratLight pb-7 md:mt-0 md:pb-14 text-white ml-[6%]">Our Projects</p>
      <div class="md:grid md:grid-cols-10 mt-12 gap-x-6">
        <div class="col-span-3 2xl:flex 2xl:justify-center">
          <div class="flex flex-col w-full justify-between gap-y-8">
            <div class="flex flex-col mb-4 items-center md:items-start w-full">
              <a @click="showVideoPlay(1)" class="flex flex-col items-end gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/deloitte.png" alt="Deloitte" class="lg:w-[80%] w-full cursor-pointer">
                <span class="font-MontserratLight hidden lg:flex text-white w-[80%] text-align-start">Deloitte</span>
              </a>
            </div>
            <div class="h-[320px] hidden md:flex items-center justify-center md:justify-start mt-20">
              <span class="text-white font-MontserratLight ml-[21%] text-lg text-left lg:w-1/3">We stay connected with creativity</span>
            </div>
            <div class="flex flex-col items-center md:items-start w-full">
              <a @click="showVideoPlay(2)" class="flex flex-col items-end gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/future-home.png" alt="Future Home" class="lg:w-[80%] w-full cursor-pointer">
                <span class="text-white text-align-start hidden lg:flex w-[80%] font-MontserratLight">Future Home</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-span-4 md:mx-5 mt-12 md:mt-0">
          <div class="flex flex-col gap-y-6 lg:gap-y-16">
            <div class="mb-4 w-full flex flex-col items-center md:items-start">
              <a @click="showVideoPlay(3)" class="flex flex-col gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/aquapark.png" alt="AquaPark" class="max-h-full max-w-full cursor-pointer">
                <span class="text-white hidden lg:flex text-align-start">AquaPark</span>
              </a>
            </div>
            <div class="mb-4 w-full flex flex-col items-center md:items-start">
              <a @click="showVideoPlay(4)" class="flex flex-col gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/moncheri.png" alt="MonCheri" class="max-h-full max-w-full lg:pt-20 cursor-pointer">
                <span class="text-white text-align-start hidden lg:flex font-MontserratLight">MonCheri CoffeeShop</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-span-3 lg:w-[80%] flex flex-col items-end">
          <div class="flex flex-col h-full w-full justify-between gap-y-6">
            <div class="hidden md:flex justify-end w-full mt-16 md:mt-0">
              <span class="font-MontserratLight text-white text-lg ml-8 mr-3 text-right">Our agency creates unique project for the digital sphere. We approach each task individually</span>
            </div>
            <div class="mt-8 md:mt-0 w-full flex-col mb-4 flex items-center md:items-start">
              <a @click="showVideoPlay(5)" class="flex flex-col gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/real-estate.png" alt="Real Estate" class="w-full cursor-pointer">
                <span class="text-white text-align-start hidden lg:flex font-MontserratLight">Real Estate</span>
              </a>
            </div>
            <div class="w-full flex-col flex items-center md:items-start">
              <a @click="showVideoPlay(6)" class="flex flex-col gap-y-3 w-full max-h-full max-w-full">
                <img src="../assets/img/homepage/nova-home.png" alt="Nova Home" class="w-full cursor-pointer">
                <span class="text-white text-align-start hidden lg:flex font-MontserratLight">Nova Home</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
    <hr class="mt-12 mb-12">
    <div ref="clients" class="flex flex-col gap-y-5 p-7 md:p-14">
      <span class="text-4xl md:text-7xl w-1/2 text-left text-white items-center font-MontserratLight">Partners</span>
      <span class="text-left text-white opacity-75 text-[14px] md:text-[16px]">We had pleasure of working with.</span>
      <div class="grid grid-cols-3 gap-2 sm:grid-cols-8 sm:gap-x-4" style="width: 80%">
        <img src="../assets/img/partners/AquaPark.png" alt="AquaPark" class="pr-6 opacity-100">
        <img src="../assets/img/partners/Century21.png" alt="Century21" class="pr-6 opacity-50">
        <img src="../assets/img/partners/Deloite.png" alt="Deloite" class="pr-6 opacity-50">
        <img src="../assets/img/partners/Euro1.png" alt="Euro1" class="pr-6 opacity-50">
        <img src="../assets/img/partners/FutureHome.png" alt="FutureHome" class="pr-6 opacity-50">
        <img src="../assets/img/partners/Moncheri.png" alt="Moncheri" class="pr-6 opacity-50">
        <img src="../assets/img/partners/NovaHome.png" alt="NovaHome" class="pr-6 opacity-50">
        <img src="../assets/img/partners/TheFoodsafetyProject.png" alt="TheFoodsafetyProject" class="pr-6 opacity-50">
      </div>
    </div>
    <hr class="mt-12 mb-12">
    <div ref="startProject" class="flex items-center sm:flex-nowrap justify-evenly w-full sm:justify-between md:gap-x-12 px-7 md:px-14 sm:p-14">
      <span class="text-2xl md:text-7xl w-full sm:w-1/2 text-left text-white items-center font-MontserratLight">Let's start <br> your project</span>
      <div class="w-1/2 sm:h-56">
        <a href="mailto: herostudioproduction@gmail.com">
          <img src="@/assets/img/say-hi.png" alt="say-hi" class="max-h-full max-w-full">
        </a>
      </div>
    </div>
    <hr class="mt-12">
    <div ref="contact" class="font-MontserratLight md:text-xl sm:inline-flex w-full justify-between text-white p-7 md:p-14 items-end">
      <div class=" flex flex-col md:h-[100px] justify-between items-start md:items-center gap-y-3 mt-4">
        <span class="">Social</span>
        <ul class="flex space-x-2 md:space-x-4">
          <li>
            <a href="https://instagram.com/herostudio.al" target="_blank" class="flex justify-center items-center"><i class="fab fa-instagram icon fill-current text-white opacity-75"></i></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/herostudioal/"  target="_blank" class="flex justify-center items-center">
              <i class="fab fa-linkedin-in icon text-white opacity-75"></i>
            </a>
          </li>
          <li>
            <a href="https://www.behance.net/herostudio"  target="_blank" class="flex justify-center items-center">
              <i class="fab fa-behance icon text-white opacity-75"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/herostudioo?mibextid=LQQJ4d" target="_blank" class="flex justify-center items-center">
              <i class="fab fa-facebook-f icon text-white opacity-75"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="flex-col flex md:h-[100px] justify-between items-start md:items-center gap-y-3 mt-4">
        <span class="">Phone</span>
        <a href="tel:+355 6801010101" class="flex inline-flex space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 256 256" xml:space="preserve">
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
              <path d="M 83.576 87.253 C 56.503 104.918 -15.416 32.615 3.794 6.316 c 0.902 -1.235 2.258 -2.065 3.736 -2.454 l 13.988 -3.681 c 2.512 -0.661 5.144 0.53 6.306 2.853 l 7.475 14.949 c 0.798 1.595 0.77 3.479 -0.073 5.051 l -5.855 10.907 c -1.145 2.133 -0.757 4.765 0.955 6.477 l 19.56 19.56 c 1.671 1.671 4.223 2.085 6.336 1.028 l 11.313 -5.657 c 1.477 -0.738 3.208 -0.773 4.712 -0.094 l 13.946 6.294 c 2.295 1.036 3.591 3.497 3.144 5.974 c -1.016 5.635 -2.104 11.666 -2.725 15.112 C 86.271 84.525 85.183 86.205 83.576 87.253 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            </g>
            </svg>
          <span>+355695874393</span>
        </a>
      </div>
      <div class="flex flex-col md:h-[100px] justify-between items-start md:items-center gap-y-3 mt-4">
        <span class="">Say Hi</span>
        <a href="mailto:herostudio@gmail.com" class="flex inline-flex space-x-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" xml:space="preserve">
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
              <path d="M 45 51.815 l 45 -33.87 v -1.967 c 0 -2.03 -1.646 -3.676 -3.676 -3.676 H 3.676 C 1.646 12.302 0 13.948 0 15.978 v 1.967 L 45 51.815 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
              <path d="M 47.405 60.019 c -0.712 0.536 -1.559 0.804 -2.405 0.804 s -1.693 -0.268 -2.405 -0.804 L 0 27.958 v 46.064 c 0 2.03 1.646 3.676 3.676 3.676 h 82.648 c 2.03 0 3.676 -1.646 3.676 -3.676 V 27.958 L 47.405 60.019 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            </g>
            </svg>
          <span>herostudioproduction@gmail.com</span>
        </a>
      </div>

    </div>
    <div class="embedsocial-hashtag px-7" data-ref="3a14b3d117d05cadbe4905d00f7d76f3cbd09451">
      <a class="feed-powered-by-es feed-powered-by-es-slider-new"><span>→</span></a></div>

    <hr class="mt-12 mb-12">
    <footer-component></footer-component>
    <VideoPlay v-if="videoPlay" :video-src="videoSrc" @closeVideoPlay="closeVideoPlay" />

  </div>
</template>

<script>
// @ is an alias to /src
import '@/assets/css/tailwind.css'
import Navbar from "@/components/HomeNavbar.vue"
import FooterComponent from "@/components/FooterComponent";
import VideoPlay from "@/components/VideoPlay";

export default {
  name: 'HomeView',
  components: {
    Navbar,
    FooterComponent,
    VideoPlay
  },
  data() {
    return {
      scrollPosition: 50,
      videoPlay: false,
      videoSrc: null,
      videos: [
        {id: 1, name:'Deloitte', videoSrc: 'https://player.vimeo.com/video/796699215?h=d96ec2af1e'},
        {id: 2, name:'Future Home', videoSrc: 'https://player.vimeo.com/video/802850608?h=ae5204648b'},
        {id: 3, name:'Aquapark', videoSrc: 'https://player.vimeo.com/video/802857436?h=7fb3594820'},
        {id: 4, name:'Mon Cheri',videoSrc: 'https://player.vimeo.com/video/786717967?h=490b884758'},
        {id: 5, name:'Real Estate', videoSrc: 'https://player.vimeo.com/video/796700812?h=835ee216d'},
        {id: 6, name:'Nova Home',videoSrc: 'https://player.vimeo.com/video/776339869?h=7e3e34713b'},
      ]
    }
  },
  methods: {
    showVideoPlay(id) {
      this.videoPlay = true
      const result = JSON.parse(JSON.stringify(
          this.videos.filter(obj => {
            return obj.id === id
          })
      ))

      console.log(result)

      this.videoSrc = result[0].videoSrc
      document.body.classList.add("modal-open");
      document.getElementById("navbar").classList.remove("change_color");
    },
    closeVideoPlay() {
      this.videoPlay = false;
      document.body.classList.remove("modal-open");
      document.getElementById("navbar").classList.add("change_color");
      window.scrollTo({
        top: document.getElementById("portfolio").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    window.scrollTo(0, 0);
    var element = document.getElementById('video');
    element.muted = true;
  }
}
</script>
<style>
.change_color {
  /*background: linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(215,211,211,1) 98%);*/
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-radius: 0.2em; /* 2px */
  position: fixed;
  padding-bottom: 0 !important;
  padding-top: 15px !important;
  z-index: 10000;
}
@media only screen and (min-width: 769px) {
  .change_color {
    background: linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(215,211,211,1) 98%);
  }
}
body.modal-open {
  overflow: hidden;
}
</style>
